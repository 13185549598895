import React from 'react';
import {
    TechnologyAdvantagesContent,
} from "./style";
import { Slider } from "../slider";
import Typography from "@mui/material/Typography";

const TechnologyAdvantagesItemsSlider = ({ advantages, index }) => {
    return (
        <Slider
            settings={{
                autoplay: false,
            }}
            isArrowBox
            width='100%'
            margin='0px'
            portalIdNext={`technology-page-next-arrow-${index + 1}`}
            portalIdPrev={`technology-page-prev-arrow-${index + 1}`}
            wrapperProps={{
                marginBottom: '68px',
            }}
        >
            {advantages?.map((item) => {
                return (
                    <TechnologyAdvantagesContent key={item.id}>
                        <Typography
                            variant='h4'
                            component='div'
                            className='title'
                            dangerouslySetInnerHTML={{
                                __html: item.title,
                            }}
                        />
                        <Typography
                            variant='body2'
                            component='div'
                            className='description'
                        >
                            {item.description}
                        </Typography>
                    </TechnologyAdvantagesContent>
                );
            })}
        </Slider>
    );
};

export default TechnologyAdvantagesItemsSlider;
