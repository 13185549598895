import React from "react";

const TechnologyTitleFirstImg = ({ width = 260, height = 183 }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 260 183"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M228.063 178.219C239.002 190.702 262.895 166.171 199.41 70.1217C162.775 14.7106 129.508 5.88506 122.706 22.6483C110.477 52.7139 139.157 64.401 129.679 50.7897C115.896 30.9974 70.6821 21.3691 34.5056 33.5228C-1.19422 45.5211 16.1976 73.5331 18.086 75.2777" stroke="#151515" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M227.3 174.452C226.198 174.31 225.19 175.088 225.049 176.19C224.907 177.292 225.685 178.3 226.787 178.441C227.888 178.583 228.896 177.805 229.038 176.703C229.18 175.602 228.402 174.594 227.3 174.452Z" fill="#151515"/>
        <path d="M17.7896 73.103C16.3431 72.9168 15.0196 73.9384 14.8334 75.3849C14.6472 76.8313 15.6689 78.1549 17.1153 78.3411C18.5618 78.5273 19.8853 77.5056 20.0715 76.0592C20.2577 74.6127 19.2361 73.2892 17.7896 73.103Z" fill="#151515"/>
        <path d="M73.2256 50.5507C75.9834 49.9094 77.7194 47.0638 77.103 44.1948C76.4865 41.3258 73.7512 39.5199 70.9934 40.1612C68.2356 40.8024 66.4997 43.648 67.1161 46.517C67.7325 49.386 70.4678 51.192 73.2256 50.5507Z" fill="#24CA49"/>
      </svg>
  );
};

export default TechnologyTitleFirstImg;
