import React, {useRef, useState} from "react";
import Container from "../container";
import Box from "@mui/material/Box";
import TechnologyItem from "./TechnologyItem";

const TechnologyList = ({ items }) => {
    const containerReference = useRef(null);

    const [expanded, setExpanded] = useState(items[0].id);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container ref={containerReference}>
            <Box width='100%'>
                {items?.map((item, index) => {
                    return <TechnologyItem
                        index={index}
                        containerReference={containerReference}
                        key={item.id}
                        item={item}
                        expanded={expanded === item.id}
                        onChange={handleChange(item.id)}
                    />;
                })}
            </Box>
        </Container>
    );
};

export default TechnologyList;