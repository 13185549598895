import React, { useEffect, useState } from 'react';
import {
    AccordionWrapper,
    TechnologyAdvantagesAdaptiveContainer, TechnologyAdvantagesBackground, TechnologyAdvantagesContainer,
    TechnologyItemArrow, TechnologyItemDescription, TechnologyItemTop,
} from "./style";
import ArrowRight from "../../assets/icons/ArrowRight";
import TechnologyAdvantagesItemsSlider from "./TechnologyAdvantagesItemsSlider";
import TechnologyAdvantagesItems from "./TechnologyAdvantagesItems";
import { useBreakpointValue } from "../../hooks/use-breakpoint-value";
import useDragList from "../../hooks/use-drag-list";
import Typography from "@mui/material/Typography";
import { AccordionDetails, AccordionSummary } from "@mui/material";

const TechnologyItem = ({ item, index, containerReference, expanded, onChange, defaultExpanded }) => {
    const isMobile = useBreakpointValue(["smallMobile", "mobile"]);
    const expertiseReference = useDragList();

    const [expertiseStyles, setExpertiseStyles] = useState({});

    useEffect(() => {
        if (containerReference.current) {
            setExpertiseStyles({
                marginLeft: `${containerReference.current.offsetLeft * -1}px`,
                paddingLeft: `${containerReference.current.offsetLeft}px`,
                width: `calc(100% + ${containerReference.current.offsetLeft * 2}px)`,
            });
        }
    }, []);

    return (
        <AccordionWrapper
            expanded={expanded}
            onChange={onChange}
            defaultExpanded={defaultExpanded}
        >
            <AccordionSummary
                id='panel1-header'
                aria-controls='panel1-content'
                expandIcon={<TechnologyItemArrow
                    sx={{
                        "& svg": {
                            transform: expanded ? "rotate(-90deg)" : "rotate(0deg)",
                        },
                    }}
                >
                    <ArrowRight />
                </TechnologyItemArrow>}
            >
                <TechnologyItemTop>
                    <Typography variant='h2' component='div' fontWeight='400'>
                        {item.title}
                    </Typography>
                </TechnologyItemTop>
            </AccordionSummary>
            <AccordionDetails>
                {item.description &&
                    <TechnologyItemDescription variant='body2' component='div'>
                        {item.description}
                    </TechnologyItemDescription>
                }
                {isMobile ? (
                    <TechnologyAdvantagesAdaptiveContainer>
                        <TechnologyAdvantagesItemsSlider index={index} advantages={item.advantages} />
                        <TechnologyAdvantagesBackground />
                    </TechnologyAdvantagesAdaptiveContainer>
                ) : (
                    <TechnologyAdvantagesContainer
                        ref={expertiseReference}
                        sx={expertiseStyles}
                    >
                        <TechnologyAdvantagesItems advantages={item.advantages} />
                    </TechnologyAdvantagesContainer>
                )}
            </AccordionDetails>
        </AccordionWrapper>
    );
};

export default TechnologyItem;
