import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import palette from "../../theme/palette";
import theme from "../../theme";

export const TechnologyServicesWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    marginTop: '120px',

    [theme.breakpoints.down('md')]: {
        marginTop: '40px',
    },
});

export const TechnologyServicesInner = styled(Box)({
    padding: '60px 0 34px',
    background: palette.porcelain,

    '&:nth-of-type(2n)': {
        padding: '120px 0 94px',
        background: 'transparent',

        '& h2': {
            order: 1,
            justifyContent: 'flex-end',
        },

        '& .technologyTitle': {
            order: 1,
        },

        '& svg': {
            order: 0,
            marginLeft: 0,
            marginRight: "44px",
            transform: "rotate(180deg)",
        },
    },

    '& .technologyServicesInnerContainer': {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    [theme.breakpoints.down('xl')]: {
        '& .technologyServicesInnerContainer': {
            flexDirection: 'column',
        },

        '&:nth-of-type(2n)': {
            padding: '60px 0 34px',

            '& h2': {
                order: 0,
            },
        },
    },

    [theme.breakpoints.down('lg')]: {
        padding: '40px 0 14px',

        '&.design': {
            marginBottom: '26px',
        },

        '&:nth-of-type(2n)': {
            padding: '60px 0 34px',
        },
    },

    [theme.breakpoints.down('md')]: {
        '&:nth-of-type(2n)': {
            '& h2': {
                justifyContent: 'flex-start',
            },

            '& .technologyTitle': {
                order: 0,
            },

            '& svg': {
                marginRight: 0,
                marginLeft: '44px',
                transform: "rotate(0)",
            },
        },
    },
});

export const TechnologyServicesCategoryTitle = styled(Typography)({
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: '490px',
    width: '100%',
    fontWeight: '400',
    lineHeight: '56px',

    '& svg': {
        marginRight: 0,
        marginLeft: '44px',
    },

    [theme.breakpoints.down('xl')]: {
        maxWidth: '100%',
        marginBottom: '44px',
    },

    [theme.breakpoints.down('md')]: {
        marginBottom: '26px',
    },
});

export const TechnologyServicesSubcategoryWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    maxWidth: '390px',
    width: '100%',

    '&.design': {
        justifyContent: 'flex-start',
        borderBottom: `1px solid ${palette.amethystSmoke}`,
    },

    [theme.breakpoints.down('xl')]: {
        justifyContent: 'flex-start',
        maxWidth: '100%',
    },

    [theme.breakpoints.down('lg')]: {
        '&.design': {
            borderBottom: `1px solid ${palette.amethystSmoke}`,
        },
    },
});

export const TechnologyServicesSubcategoryInner = styled(Box)({
    width: '100%',
    marginBottom: '26px',
    paddingBottom: '16px',
    borderBottom: `1px solid ${palette.amethystSmoke}`,

    '&.design': {
        width: 'auto',
        marginBottom: 0,
        paddingRight: '26px',
        borderBottom: 'none',
    },

    [theme.breakpoints.down('lg')]: {
        '&.design': {
            marginBottom: 0,
            borderBottom: 'none',
        },
    },
});

export const TechnologyServicesSubcategoryInnerTitle = styled(Typography)({
    color: palette.amethystSmoke,
    fontWeight: 400,
    letterSpacing: '-0.01em',

    '&.design': {
        color: palette.primary,
    },
});

export const TechnologyServicesItemsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
});

export const TechnologyServicesItem = styled(Typography)({
    margin: '16px 26px 0 0',

    [theme.breakpoints.down('sm')]: {
        marginTop: '8px',
    },
});
