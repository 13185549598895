import React from 'react';

import {
    TechnologyAdvantagesContent
} from "./style";
import Typography from "@mui/material/Typography";

const TechnologyAdvantagesItems = ({ advantages }) => {
    return (
        <>
            {advantages?.map((item) => {
                return (
                    <TechnologyAdvantagesContent key={item.id}>
                        <Typography
                            variant='h4'
                            component='div'
                            className='title'
                            dangerouslySetInnerHTML={{
                                __html: item.title,
                            }}
                        />
                        <Typography
                            variant='body2'
                            component='div'
                            className='description'
                        >
                            {item.description}
                        </Typography>
                    </TechnologyAdvantagesContent>
                );
            })}
        </>
    );
};

export default TechnologyAdvantagesItems;
