import React from "react";

const TechnologyTitleSecondImg = ({ width = 22, height = 23 }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path d="M6.75509 0.501094L14.2157 8.94623L21.6719 17.3703L10.9168 19.8712L0.161681 22.372L3.46065 11.4471L6.75509 0.501094Z" fill="#5A32FB"/>
      </svg>
  );
};

export default TechnologyTitleSecondImg;
