import React from "react";

const TechnologyTitleThirdImg = ({ width = 42, height = 29 }) => {
  return (
      <svg
          width={width}
          height={height}
          viewBox="0 0 42 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path d="M39.1401 28.3979C40.2509 28.3979 41.1513 27.4975 41.1513 26.3867C41.1513 25.2759 40.2509 24.3755 39.1401 24.3755C38.0294 24.3755 37.1289 25.2759 37.1289 26.3867C37.1289 27.4975 38.0294 28.3979 39.1401 28.3979Z" fill="#151515" stroke="#151515" strokeMiterlimit="10"/>
          <path d="M3.73922 9.36363L2.67245 5.04345L7.01783 4.0845L9.20256 5.44133L4.85717 6.40028L5.92395 10.7205L3.73922 9.36363Z" fill="#151515"/>
          <path d="M8.53879 9.94407C8.94861 9.28418 9.44864 8.78372 10.0389 8.44269C10.6373 8.1068 11.262 7.95043 11.9127 7.97357C12.5634 7.9967 13.1826 8.19072 13.7701 8.55563C14.217 8.83316 14.5847 9.19211 14.8731 9.63249C15.1664 10.065 15.3379 10.509 15.3876 10.9644L17.3123 7.86527L19.075 8.95999L13.6594 17.68L11.8967 16.5853L12.4968 15.619C12.0816 15.8075 11.6186 15.8791 11.1077 15.8339C10.5967 15.7888 10.0847 15.6069 9.57165 15.2882C8.99237 14.9285 8.55056 14.4581 8.24622 13.8772C7.94189 13.2963 7.8076 12.6632 7.84336 11.9778C7.89227 11.2897 8.12408 10.6118 8.53879 9.94407ZM13.9363 13.3289C14.1851 12.9282 14.3146 12.5351 14.3247 12.1495C14.3398 11.756 14.2529 11.3973 14.0643 11.0733C13.8805 10.7414 13.6272 10.4753 13.3045 10.2749C12.9817 10.0744 12.6374 9.96402 12.2717 9.94368C11.9059 9.92334 11.5458 10.0045 11.1915 10.1873C10.8504 10.3673 10.5578 10.6537 10.3139 11.0465C10.0699 11.4393 9.93802 11.8363 9.91813 12.2376C9.9114 12.6362 9.99579 12.9989 10.1713 13.3256C10.3551 13.6575 10.6042 13.9211 10.9187 14.1164C11.2414 14.3168 11.5898 14.4298 11.9639 14.4552C12.3428 14.4729 12.7029 14.3917 13.044 14.2117C13.3901 14.0238 13.6875 13.7295 13.9363 13.3289Z" fill="#151515"/>
          <path d="M20.5271 11.8213C20.221 11.6312 20.0205 11.3815 19.9259 11.0723C19.8443 10.7604 19.889 10.467 20.0597 10.1921C20.2305 9.91711 20.4713 9.75096 20.7821 9.69363C21.1061 9.63357 21.4212 9.69863 21.7274 9.88879C22.0335 10.079 22.2274 10.33 22.3089 10.6419C22.4036 10.951 22.3655 11.2431 22.1948 11.5181C22.024 11.793 21.7767 11.9605 21.4527 12.0206C21.1418 12.0779 20.8333 12.0115 20.5271 11.8213ZM20.9007 13.131L16.8463 19.6592L15.1084 18.5799L19.1628 12.0517L20.9007 13.131Z" fill="#151515"/>
          <path d="M22.1266 20.8321L26.9747 16.9033L28.8243 18.052L22.2252 22.9998L20.115 21.6892L21.637 13.5883L23.499 14.7447L22.1266 20.8321Z" fill="#151515"/>
          <path d="M29.9906 18.3518L32.1878 19.7163L33.2545 24.0365L28.9092 24.9954L26.712 23.6309L31.0698 22.6797L29.9906 18.3518Z" fill="#151515"/>
      </svg>
  );
};

export default TechnologyTitleThirdImg;
