import React from "react";
import {
    TechnologyServicesInner, TechnologyServicesSubcategoryInner, TechnologyServicesSubcategoryInnerTitle,
    TechnologyServicesSubcategoryWrapper, TechnologyServicesCategoryTitle,
    TechnologyServicesWrapper, TechnologyServicesItemsWrapper, TechnologyServicesItem
} from "./style";
import Container from "../container";
import ArrowHeading from "../arrowHeading";
import Box from "@mui/material/Box";
const TechnologyServices = ({ technologyCategories }) => {
    return (
        <TechnologyServicesWrapper>
            {technologyCategories?.data?.map((category) => {
                return (
                    <TechnologyServicesInner key={category.id} className={category?.attributes?.isDesign && 'design'}>
                        <Container className='technologyServicesInnerContainer'>
                            <TechnologyServicesCategoryTitle variant='h2'>
                                <Box className='technologyTitle'>{category?.attributes?.title}</Box>
                                <ArrowHeading />
                            </TechnologyServicesCategoryTitle>
                            <TechnologyServicesSubcategoryWrapper className={category?.attributes?.isDesign && 'design'}>
                                {category?.attributes?.subcategories?.data?.map((subcategory) => {
                                    return (
                                        <TechnologyServicesSubcategoryInner key={subcategory.id} className={subcategory?.attributes?.isDesign && 'design'}>
                                            {subcategory?.attributes?.isDesign
                                                ? (
                                                    <TechnologyServicesSubcategoryInnerTitle variant='body2' component='div' className='design'>
                                                        {subcategory?.attributes?.title}
                                                    </TechnologyServicesSubcategoryInnerTitle>
                                                )
                                                : (
                                                    <TechnologyServicesSubcategoryInnerTitle variant='h4' component='div'>
                                                        {subcategory?.attributes?.title}
                                                    </TechnologyServicesSubcategoryInnerTitle>
                                                )
                                            }
                                            <TechnologyServicesItemsWrapper>
                                                {subcategory?.attributes?.items?.data?.map((item) => {
                                                    return (
                                                        <TechnologyServicesItem key={item.id} variant='body2' component='div'>
                                                            {item?.attributes?.title}
                                                        </TechnologyServicesItem>
                                                    )
                                                })}
                                            </TechnologyServicesItemsWrapper>
                                        </TechnologyServicesSubcategoryInner>
                                    );
                                })}
                            </TechnologyServicesSubcategoryWrapper>
                        </Container>
                    </TechnologyServicesInner>
                )
            })}
        </TechnologyServicesWrapper>
    );
};

export default TechnologyServices;
