import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import theme from "../theme";
import Typography from "@mui/material/Typography";

export const TechnologyTop = styled(Box)({
    margin: '108px 0 36px',

    [theme.breakpoints.down('lg')]: {
        margin: '130px 0 60px',
    },

    "@media (max-width: 375px)": {
        margin: '100px 0 60px',
    },
});

export const TechnologyTopContent = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',

    [theme.breakpoints.down('lg')]: {
        '& img': {
            width: "40%",
        },
    },

    [theme.breakpoints.down("md")]: {
        '& img': {
            display: "none",
        },
    },
});

export const TechnologyTitleWrapper = styled(Box)({
    position: "relative",
    width: "100%",

    [theme.breakpoints.down('lg')]: {
        width: "60%",
    },

    [theme.breakpoints.down("md")]: {
        maxWidth: "440px",
        width: "100%",
    },
});

export const TechnologyTitleFirstIcon = styled(Box)({
    display: "none",

    [theme.breakpoints.down("md")]: {
        display: "flex",
        position: "absolute",
        top: "-70px",
        right: "-20px",
        width: "260px",
        height: "183px",

        "& svg": {
            width: "100%",
        },
    },

    "@media (max-width: 375px)": {
        display: "none",
    },
});

export const TechnologyTitleSecondIcon = styled(Box)({
    display: "none",

    [theme.breakpoints.down("md")]: {
        display: "flex",
        position: "absolute",
        bottom: "-48px",
        left: "74px",
        width: "22px",
        height: "23px",

        "& svg": {
            width: "100%",
        },
    },

    "@media (max-width: 375px)": {
        display: "none",
    },
});

export const TechnologyTitleThirdIcon = styled(Box)({
    display: "none",

    [theme.breakpoints.down("md")]: {
        display: "flex",
        position: "absolute",
        bottom: "-60px",
        right: "20px",
        width: "42px",
        height: "29px",

        "& svg": {
            width: "100%",
        },
    },

    "@media (max-width: 375px)": {
        display: "none",
    },
});

export const TechnologyTitle = styled(Typography)({
    fontWeight: 600,

    "@media (max-width: 980px)": {
        fontSize: '48px',
        lineHeight: '56px',
    },

    "@media (max-width: 375px)": {
        fontSize: '34px',
        lineHeight: '42px',
    },
});