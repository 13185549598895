import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import theme from "../../theme";
import palette from "../../theme/palette";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";

export const AccordionWrapper = styled(Accordion)({
    backgroundColor: "transparent",
    boxShadow: "none",

    "&:before": {
        display: "none",
    },

    "&:first-of-type": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },

    "& .MuiAccordionSummary-root": {
        padding: 0,

        "& .MuiAccordionSummary-content": {
            margin: 0,
        },
    },

    "& .MuiAccordionDetails-root": {
        padding: 0,
    },
});

export const TechnologyItemTop = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    margin: '32px 0',

    [theme.breakpoints.down('md')]: {
        margin: '24px 0',
    },
});

export const TechnologyItemDescription = styled(Typography)({
    maxWidth: '780px',
    width: '100%',
    marginBottom: '44px',
});

export const TechnologyItemArrow = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '52px',
    height: '52px',
    border: `1px solid ${palette.primary}`,
    borderRadius: '100px',

    "& svg path": {
        fill: palette.primary,
    },
});

export const TechnologyAdvantagesContainer = styled(Box)({
    display: 'flex',
    position: 'relative',
    overflowX: 'scroll',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s',
    willChange: 'transform',
    userSelect: 'none',
    cursor: 'move',
    scrollbarColor: 'transparent transparent',
    scrollbarWidth: 'thin',

    '&::-webkit-scrollbar': {
        width: '3px',
        height: '3px',
    },

    '&::-webkit-scrollbar-thumb': {
        background: 'transparent',
    },

    '&::-webkit-scrollbar-track': {
        background: 'transparent',
    },

    '& > .active': {
        cursor: 'grabbing',
    },

    [theme.breakpoints.down('xl')]: {
        marginTop: '56px',
    },

    [theme.breakpoints.down('lg')]: {
        marginTop: '24px',
    },
});

export const TechnologyAdvantagesAdaptiveContainer = styled(Box)({
    overflow: 'hidden',
    display: 'flex',
    position: 'relative',
    marginRight: '-16px',
    paddingRight: '16px',

    '@media(max-width: 641px)': {
        marginTop: '26px',
    },
    '@media(min-width: 641px)': {
        display: 'none',
    },
});

export const TechnologyAdvantagesBackground = styled(Box)({
    position: 'absolute',
    top: '90px',
    right: 0,
    width: '252px',
    height: '100%',
    background: palette.purpleBlue,
    zIndex: -1,
});

export const TechnologyAdvantagesContent = styled(Box)({
    display: 'inline-block',
    flexShrink: 0,
    float: 'left',
    maxWidth: '364px',
    width: '100%',
    marginRight: '44px',
    padding: '24px',
    color: palette.primary,
    backgroundColor: palette.secondary,
    border: `1px solid ${palette.amethystSmoke}`,

    '& .title': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flexWrap: 'wrap',
        height: '90px',
        paddingBottom: '24px',
        marginBottom: '24px',
        borderBottom: `1px solid ${palette.primary}`,

        p: {
            whiteSpace: 'break-spaces',
        },
    },

    '& .description': {
        whiteSpace: 'break-spaces',
    },

    [theme.breakpoints.down('md')]: {
        maxWidth: '100%',
        height: '100%',
        marginRight: 0,
        color: palette.secondary,
        backgroundColor: palette.primary,

        '& .title': {
            borderBottom: `1px solid ${palette.secondary}`,
        },
    },

    "@media (max-width: 375px)": {
        padding: '16px',
        fontSize: '14px',
        lineHeight: '20px',

        '& .title': {
            height: 'auto',
            paddingBottom: '16px',
            marginBottom: '16px',
            fontSize: '20px',
            lineHeight: '28px',

            strong: {
                fontSize: '20px',
                lineHeight: '28px',
            },
        },
    },
});