import React from "react";
import { graphql } from "gatsby";
import Layout from "../layout";
import Seo from "../components/seo";
import Container from "../components/container";
import {
    TechnologyTitle,
    TechnologyTitleFirstIcon,
    TechnologyTitleSecondIcon,
    TechnologyTitleThirdIcon,
    TechnologyTitleWrapper,
    TechnologyTop, TechnologyTopContent
} from "../styles/technology";
import { HOST_IMAGE_URL } from "../constants";
import TechnologyTitleFirstImg from "../assets/icons/TechnologyTitleFirstImg";
import TechnologyTitleSecondImg from "../assets/icons/TechnologyTitleSecondImg";
import TechnologyTitleThirdImg from "../assets/icons/TechnologyTitleThirdImg";
import TechnologyList from "../components/technologyAdvantages";
import TechnologyServices from "../components/technologyServices";

function AboutUs({
  data: {
    strapi: { technology: { data: { attributes } }, technologyCategories },
  },
}) {

  return (
    <Layout>
        <Seo
            title={attributes?.seo?.metaTitle}
            description={attributes?.seo?.metaDescription}
            image={attributes?.seo?.metaImage?.data?.attributes?.url}
        />
        <TechnologyTop>
            <Container>
                <TechnologyTopContent>
                    <TechnologyTitleWrapper>
                        <TechnologyTitle variant='h1'>
                            {attributes?.title}
                        </TechnologyTitle>
                        <TechnologyTitleFirstIcon>
                            <TechnologyTitleFirstImg />
                        </TechnologyTitleFirstIcon>
                        <TechnologyTitleSecondIcon>
                            <TechnologyTitleSecondImg />
                        </TechnologyTitleSecondIcon>
                        <TechnologyTitleThirdIcon>
                            <TechnologyTitleThirdImg />
                        </TechnologyTitleThirdIcon>
                    </TechnologyTitleWrapper>
                    <img
                        src={`${HOST_IMAGE_URL + attributes?.image?.data?.attributes?.url}`}
                        alt={attributes?.image?.data?.attributes?.alternativeText}
                        title={attributes?.image?.data?.attributes?.caption}
                    />
                </TechnologyTopContent>
            </Container>
        </TechnologyTop>
        <TechnologyList items={attributes?.items} />
        <TechnologyServices technologyCategories={technologyCategories} />
    </Layout>
  );
}

export default AboutUs;

export const query = graphql`
  query {
    strapi {
      technology {
        data {
          attributes {
            seo {
              ...seo
            }
            title
            image {
              data {
                attributes {
                  url
                  alternativeText
                  caption
                }
              }
            }
            items {
                id
                title
                description
                advantages {
                    id
                    title
                    description
                }
            }
          }
        }
      }
      technologyCategories {
        data {
            id
            attributes {
                title
                isDesign
                subcategories {
                    data {
                        id
                        attributes {
                            title
                            isDesign
                            items {
                                data {
                                    id
                                    attributes {
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      }
    }
  }
`;
